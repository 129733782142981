import { TextBadgeColor } from "@bps/fluent-ui";
import {
  InviteStatus,
  UserInviteDto
} from "@libs/api/gateways/bp-id/bp-id.dtos";

export const getInvitationStatusColor = (
  invite: UserInviteDto
): TextBadgeColor => {
  if (invite.status === InviteStatus.Redeemed) return TextBadgeColor.blue;

  if (invite.status === InviteStatus.Expired) return TextBadgeColor.red;

  if (invite.status === InviteStatus.Pending) return TextBadgeColor.green;
  return TextBadgeColor.yellow;
};
