import { Validator } from "@components/form/validation/Validator";
import { MobileAppFormValues } from "./mobile-app-form.types";
import { DECIMAL_NUMBER_REGEX } from "@libs/validation/validation-rules.constants";

export const mobileAppValidator = new Validator<MobileAppFormValues>(
  validator => ({
    maintenanceModeDescription: [
      validator.string().maxLength(250),
      validator.custom().predicate({
        when: (_value, _parent, formValues) =>
          !!formValues.maintenanceModeEnabled,
        then: validator.string().required()
      })
    ],
    minimumVersionNumber: validator.string().pattern(DECIMAL_NUMBER_REGEX, {
      message: "Only decimal numbers up to 5 decimal places are allowed"
    }),
    appVersions: validator.array().itemArray({
      Version: [
        validator.string().required({ message: "Version is required" }),
        validator.string().pattern(DECIMAL_NUMBER_REGEX, {
          message: "Only decimal numbers up to 5 decimal places are allowed"
        })
      ],
      Message: [
        validator.string().maxLength(150),
        validator.string().required({ message: "Message is required" })
      ]
    })
  })
);
