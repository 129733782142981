import { FunctionComponent, ReactNode } from "react";
import { PresetButtonType } from "@bps/fluent-ui";
import { InvitationsArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { Item } from "@components/filter-bar/FilterBar.types";
import { FilterBar } from "@components/filter-bar/FilterBar";

interface InvitationsFilterValues
  extends Omit<
    InvitationsArgs,
    "createdStartDate" | "createdEndDate" | "expiryEndDate" | "expiryStartDate"
  > {
  createdStartDate?: Date;
  createdEndDate?: Date;
  expiryStartDate?: Date;
  expiryEndDate?: Date;
}
interface InvitationsFilterProps {
  children: (args: InvitationsArgs) => ReactNode;
}
export const InvitationsFilter: FunctionComponent<InvitationsFilterProps> = ({
  children
}) => {
  const items: Item<InvitationsFilterValues>[] = [
    {
      name: "name",
      type: "searchBox",
      props: {
        placeholder: "Search by email",
        removeSpecialCharacters: false
      },
      stickItem: true
    },
    {
      name: "tenantId",
      type: "tenantsSelect",
      props: { placeholder: "Search by tenant" }
    },
    {
      name: "userId",
      type: "usersSelect",
      props: {
        dependOnTenantSelectName: "tenantId",
        placeholder: "Search by user"
      }
    },
    {
      name: "datesRangePickerCreated",
      type: "datesRangePicker",
      props: {
        startDateProps: { label: "From" },
        endDateProps: { label: "To" },
        startDateName: "createdStartDate",
        endDateName: "createdEndDate",
        placeholder: "Created dates range"
      }
    },
    {
      name: "datesRangePickerExpiry",
      type: "datesRangePicker",
      props: {
        startDateProps: { label: "From" },
        endDateProps: { label: "To" },
        startDateName: "expiryStartDate",
        endDateName: "expiryEndDate",
        placeholder: "Expiry dates range"
      }
    }
  ];

  const presets: PresetButtonType<InvitationsFilterValues>[] = [
    {
      name: "isExpired",
      text: "Show expired",
      iconName: "BpTimerOff"
    },
    {
      name: "isRedeemed",
      text: "Show redeemed",
      iconName: "BPCheckedOut"
    }
  ];
  return (
    <FilterBar<InvitationsFilterValues>
      maxItemWidth={250}
      initialValues={{ isExpired: false, isRedeemed: false }}
      items={items}
      children={state => {
        const args: InvitationsArgs = {
          ...state.values,
          createdStartDate: state.values?.createdStartDate?.toISOString(),
          createdEndDate: state.values?.createdEndDate?.toISOString(),
          expiryStartDate: state.values?.expiryStartDate?.toISOString(),
          expiryEndDate: state.values?.expiryEndDate?.toISOString()
        };
        return children(args);
      }}
      presets={presets}
    />
  );
};
