import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { CopyToClipboardButton } from "@components/buttons/CopyToClipboardButton";
import { SectionTitle } from "@components/SectionTitle";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface FieldDeviceOverviewProps {
  device: FieldDevice;
}

export const FieldDeviceOverview: React.FC<FieldDeviceOverviewProps> = ({
  device
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const now = DateTime.now();
  const hasExpired =
    device.tokenExpiryUtc != null && now >= device.tokenExpiryUtc;

  return (
    <>
      <SectionTitle>Device Overview</SectionTitle>

      {device.tokenExpiryUtc === undefined && (
        <MessageBar messageBarType={MessageBarType.error}>
          No token has been issued for this device. Please ensure the device is
          active to initiate a request for an authentication token.
        </MessageBar>
      )}

      {hasExpired && (
        <MessageBar messageBarType={MessageBarType.warning}>
          The latest token has expired. Please visit the{" "}
          <Link
            onClick={() => navigate(`/field-mgmt/${device.id}/fd-security`)}
          >
            device security
          </Link>{" "}
          page, complete any necessary authentication actions and review the
          device secret used for this device.
        </MessageBar>
      )}

      <Stack
        tokens={{ childrenGap: 4 }}
        styles={{
          root: {
            maxWidth: 470,
            borderColor: theme.semanticColors.variantBorder,
            borderWidth: 1,
            borderStyle: "solid",
            padding: theme.spacing.s1
          }
        }}
      >
        <Stack
          horizontal
          tokens={{ childrenGap: 4 }}
          styles={{ root: { display: "flex", justifyContent: "center" } }}
        >
          <Text bold>Field Device Id: </Text>
          <Text>{device.id}</Text>
          <CopyToClipboardButton clipboardContent={device.id} />
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 4 }}
          styles={{ root: { display: "flex", justifyContent: "center" } }}
        >
          <Text bold>Cloud Connector Id: </Text>
          <Text>{device.cloudConnectorId}</Text>
          <CopyToClipboardButton clipboardContent={device.cloudConnectorId} />
        </Stack>
      </Stack>
    </>
  );
};
