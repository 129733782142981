import { Heading } from "@bps/fluent-ui";
import { withPermissions } from "@components/withPermissions";
import { MobileAppSettings } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";
import {
  MAINTENANCE_MODE,
  MAINTENANCE_MODE_DESCRIPTION,
  MINIMUM_VERSION_NUMBER,
  VERSION_UPGRADE_MESSAGES
} from "@libs/api/gateways/bp-mobile/bp-mobile.constants";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { Form } from "@components/form/Form";
import { newGuid } from "@bps/utils";
import {
  usePostMobileAppSettings,
  usePutMobileAppSettings
} from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";
import { MobileAppFormFields } from "./MobileAppFormFields";
import {
  MobileAppFormProps,
  MobileAppFormSubmitValues,
  MobileAppFormValues
} from "./mobile-app-form.types";
import { useRootStore } from "@stores/StoresProvider";
import { UseFormReturn } from "react-hook-form";
import { mobileAppValidator } from "./mobile-app-form.utils";

const MobileAppFormBase: React.FC<MobileAppFormProps> = ({
  mobileAppSettings
}) => {
  const { feedback } = useRootStore();
  const queryUpdate = usePutMobileAppSettings();
  const queryPost = usePostMobileAppSettings();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    [Permissions.BhMobileAppWrite],
    "or"
  );

  const maintenanceModeSetting = mobileAppSettings.find(
    setting => setting.settingName === MAINTENANCE_MODE
  );

  const maintenanceModeDescriptionSetting = mobileAppSettings.find(
    setting => setting.settingName === MAINTENANCE_MODE_DESCRIPTION
  );

  const versionUpgradeMessagesSetting = mobileAppSettings.find(
    setting => setting.settingName === VERSION_UPGRADE_MESSAGES
  );

  const minimumVersionNumberSetting = mobileAppSettings.find(
    setting => setting.settingName === MINIMUM_VERSION_NUMBER
  );

  const appVersions = versionUpgradeMessagesSetting
    ? JSON.parse(versionUpgradeMessagesSetting.settingValue)
    : [];

  const defaultValues: MobileAppFormValues = {
    maintenanceModeDescription: maintenanceModeDescriptionSetting?.settingValue,
    maintenanceModeEnabled: maintenanceModeSetting?.settingValue === "true",
    minimumVersionNumber: minimumVersionNumberSetting?.settingValue,
    appVersions
  };

  const onSubmit = async (
    values: MobileAppFormValues,
    form: UseFormReturn<MobileAppFormValues>
  ) => {
    const { maintenanceModeDescription } = values;
    const { maintenanceModeEnabled } = values;

    const submitValues: MobileAppFormSubmitValues = {
      maintenanceModeId: maintenanceModeSetting?.mobileAppSettingsId,
      maintenanceModeDescriptionId:
        maintenanceModeDescriptionSetting?.mobileAppSettingsId,
      versionUpgradeMessagesId:
        versionUpgradeMessagesSetting?.mobileAppSettingsId,
      minimumVersionNumberId: minimumVersionNumberSetting?.mobileAppSettingsId,
      maintenanceModeDescription,
      maintenanceModeEnabled,
      appVersions: values?.appVersions,
      minimumVersionNumber: values?.minimumVersionNumber
    };

    const mobileAppSettingsUpdates = [
      {
        mobileAppSettingsId: submitValues.maintenanceModeId,
        settingName: MAINTENANCE_MODE,
        settingValue: submitValues.maintenanceModeEnabled ? "true" : "false"
      },
      {
        mobileAppSettingsId: submitValues.maintenanceModeDescriptionId,
        settingName: MAINTENANCE_MODE_DESCRIPTION,
        settingValue: submitValues.maintenanceModeDescription
      },
      {
        mobileAppSettingsId: submitValues.versionUpgradeMessagesId,
        settingName: VERSION_UPGRADE_MESSAGES,
        settingValue: JSON.stringify(submitValues.appVersions)
      },
      {
        mobileAppSettingsId: submitValues.minimumVersionNumberId,
        settingName: MINIMUM_VERSION_NUMBER,
        settingValue: submitValues.minimumVersionNumber
      }
    ];

    const updates = mobileAppSettingsUpdates.filter(u => !!u);
    const promises = updates.map(update => {
      if (update.mobileAppSettingsId) {
        return queryUpdate.mutateAsync(update as MobileAppSettings);
      } else {
        return queryPost.mutateAsync({
          ...update,
          mobileAppSettingsId: newGuid()
        } as MobileAppSettings);
      }
    });

    try {
      await Promise.all(promises);
      feedback.success("Mobile App setting have been updated.");
      form.reset(values);
    } catch (e) {
      return e;
    }
  };

  return (
    <Form<MobileAppFormValues>
      defaultValues={defaultValues}
      validate={mobileAppValidator.validateWithParse}
      onSubmit={onSubmit}
      buttonsRootStyles={{ root: { alignSelf: "start" } }}
      hideSubmitButton={!hasWritePermission}
      showCancelButton
      cancelButtonText="Reset"
      onCancel={form => {
        form.reset(form.formState.defaultValues);
      }}
    >
      <Heading variant="section-heading">Mobile App</Heading>
      <MobileAppFormFields />
    </Form>
  );
};

export const MobileAppForm = withPermissions(
  MobileAppFormBase,
  [Permissions.BhMobileAppRead, Permissions.BhMobileAppWrite],
  "or"
);
