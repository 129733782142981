import { useMemo } from "react";

import {
  DetailsRow,
  IColumn,
  MessageBar,
  MessageBarType,
  ScrollablePane,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { DateTime } from "@bps/utils";
import { ScrollListener } from "@components/tables/ScrollListener";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { useTabsNavContext } from "@components/tabs-nav";
import { FieldDeviceDetails } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { ServiceStatus } from "./ServiceStatus";

interface FieldMgmtTableProps {
  devices: FieldDeviceDetails[];
  error?: HttpError;
  isLoading?: boolean;
  hasNextPage: boolean | undefined;
  handleScrolledToBottom: () => Promise<void>;
}

export const FieldMgmtTable = ({
  devices,
  error,
  isLoading,
  hasNextPage,
  handleScrolledToBottom
}: FieldMgmtTableProps) => {
  const theme = useTheme();
  const { addTab } = useTabsNavContext();

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        minWidth: 100,
        maxWidth: 450,
        isResizable: true,
        onRender: (device: FieldDeviceDetails) => (
          <Stack>
            <Text>{device.computerName}</Text>
            <Text variant="small">FieldDeviceId: {device.id}</Text>
            <Text variant="small">
              CloudConnectorId: {device.cloudConnectorId}
            </Text>
          </Stack>
        )
      },
      {
        key: "Operating System",
        name: "Operating System",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (device: FieldDeviceDetails) => (
          <Stack>
            <Text variant="small">{device.osName}</Text>
            <Text variant="small">{device.osVersion}</Text>
          </Stack>
        )
      },
      {
        key: "Controller Service",
        name: "Controller Service",
        minWidth: 150,
        maxWidth: 220,
        isResizable: true,
        onRender: (device: FieldDeviceDetails) => {
          const {
            controllerRunStatusSid: runStatusSid,
            controllerStatus: status,
            controllerVersion: version,
            controllerStartup: startup,
            controllerLastObservedDateTimeUtc: observedTime,
            controllerStartDateTimeUtc: startTime
          } = device;

          const statusProps = {
            runStatusSid,
            status,
            version,
            startup,
            observedTime,
            startTime
          };

          return <ServiceStatus {...statusProps} />;
        }
      },
      {
        key: "Recovery Agent Service",
        name: "Recovery Agent Service",
        minWidth: 150,
        maxWidth: 220,
        isResizable: true,
        onRender: (device: FieldDeviceDetails) => {
          const {
            recoveryAgentRunStatusSid: runStatusSid,
            recoveryAgentStatus: status,
            recoveryAgentVersion: version,
            recoveryAgentStartup: startup,
            recoveryAgentLastObservedDateTimeUtc: observedTime,
            recoveryAgentStartDateTimeUtc: startTime
          } = device;

          const statusProps = {
            runStatusSid,
            status,
            version,
            startup,
            observedTime,
            startTime
          };

          return <ServiceStatus {...statusProps} />;
        }
      },
      {
        key: "System Domain",
        name: "System Domain",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (device: FieldDeviceDetails) => device.systemDomain
      },
      {
        key: "Maintenance Script Version",
        name: "Maintenance Script Version",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (device: FieldDeviceDetails) =>
          device.maintenanceScriptVersion
      },
      {
        key: "Authentication Token",
        name: "Authentication Token",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (device: FieldDeviceDetails) => {
          const now = DateTime.now();
          if (device.tokenExpiryUtc === null) {
            return (
              <MessageBar
                messageBarType={MessageBarType.error}
                styles={{ root: { width: "min-content" } }}
              >
                Token Unavailable
              </MessageBar>
            );
          }

          const hasExpired = now >= device.tokenExpiryUtc;
          if (hasExpired) {
            return (
              <MessageBar
                messageBarType={MessageBarType.warning}
                styles={{ root: { width: "min-content" } }}
              >
                Token Expired
              </MessageBar>
            );
          }

          return (
            <MessageBar
              messageBarType={MessageBarType.success}
              styles={{ root: { width: "min-content" } }}
            >
              Token Valid
            </MessageBar>
          );
        }
      }
    ],
    []
  );

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          position: "relative",
          height: "100%",
          flex: "1 1 0",
          overflowY: "auto"
        }
      }}
    >
      <ScrollablePane>
        <ShimmeredDetailsList
          enableShimmer={isLoading}
          errorMessage={error?.message}
          isSelectedOnFocus
          columns={columns}
          items={devices}
          onRenderRow={row =>
            row ? (
              <Stack
                onClick={() => {
                  const device: FieldDeviceDetails = row.item;
                  addTab({
                    id: device.id,
                    name: device.computerName
                  });
                }}
                styles={{
                  root: {
                    "&:hover": {
                      backgroundColor:
                        theme.semanticColors.listItemBackgroundHovered,
                      cursor: "pointer"
                    }
                  }
                }}
              >
                <DetailsRow {...row} />
              </Stack>
            ) : null
          }
        />
        <ScrollListener
          hasNextPage={hasNextPage ?? false}
          onScrolledToBottom={handleScrolledToBottom}
        />
      </ScrollablePane>
    </Stack>
  );
};
