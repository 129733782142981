import { Fragment, FunctionComponent, useEffect } from "react";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import {
  DefaultButton,
  Grid,
  GridItem,
  NoDataTile,
  Separator,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DangerButton } from "@components/buttons/DangerButton";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { useFormContext, useWatch } from "react-hook-form";
import { VersionListItem } from "./mobile-app-form.types";

export const MobileAppFormFields: FunctionComponent = () => {
  const { setValue, getValues } = useFormContext();
  const appVersions: VersionListItem[] = useWatch({ name: "appVersions" });
  const maintenanceModeEnabled: boolean = useWatch({
    name: "maintenanceModeEnabled"
  });

  useEffect(() => {
    if (!maintenanceModeEnabled) {
      setValue("maintenanceModeDescription", "");
    }
  }, [maintenanceModeEnabled, setValue]);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    [Permissions.BhMobileAppWrite],
    "or"
  );

  const hasAppVersions = appVersions && appVersions?.length > 0;

  const addVersion = () => {
    const versions = getValues("appVersions");
    const newVersions = [...versions, { Message: "", Version: "" }];
    setValue("appVersions", newVersions);
  };

  const removeVersion = (index: number) => {
    const versions = getValues("appVersions");
    versions.splice(index, 1);
    setValue("appVersions", versions);
  };

  return (
    <>
      <FormTextField
        label="Maintenance Mode Description"
        name="maintenanceModeDescription"
        multiline
        disabled={!hasWritePermission || !maintenanceModeEnabled}
      />

      <FormSwitch
        name="maintenanceModeEnabled"
        label="Maintenance Mode Enabled"
        disabled={!hasWritePermission}
      />
      <Separator />

      <Stack horizontalAlign="start">
        <Text variant="xLarge">Force Upgrade</Text>
      </Stack>

      <FormTextField
        label="Minimum version number"
        name="minimumVersionNumber"
        disabled={!hasWritePermission}
        styles={{
          root: {
            width: "200px"
          }
        }}
      />

      {!hasAppVersions && (
        <NoDataTile
          textProps={{
            text:
              "No app versions available. Click 'Add version' to add a new entry."
          }}
          linkProps={{ onClick: () => addVersion(), text: "Add version" }}
        />
      )}

      {hasAppVersions && (
        <Grid
          templateColumns="100px 1fr 148px"
          childrenTokens={{ gap: 8 }}
          verticalAlign="center"
        >
          <Text bold>Version</Text>
          <Text bold>Message</Text>

          <GridItem>
            {hasWritePermission && (
              <DefaultButton
                onClick={addVersion}
                styles={{ root: { width: "fit-content" } }}
                iconProps={{ iconName: "Add" }}
              >
                Add version
              </DefaultButton>
            )}
          </GridItem>

          {appVersions.map((version: VersionListItem, index: number) => (
            <Fragment key={version.Version}>
              <FormTextField
                name={`appVersions[${index}].Version`}
                disabled={!hasWritePermission}
              />
              <FormTextField
                name={`appVersions[${index}].Message`}
                disabled={!hasWritePermission}
              />
              <DangerButton
                onClick={() => removeVersion(index)}
                disabled={!hasWritePermission}
              >
                Delete
              </DangerButton>
            </Fragment>
          ))}
        </Grid>
      )}
    </>
  );
};
