import { FunctionComponent, useMemo, useState } from "react";

import { DefaultButton, Dialog } from "@bps/fluent-ui";
import { ValidationSchema } from "@bps/utils";
import { FormApplicationsSelect } from "@components/form/fields/FormApplicationsSelect";
import { FormTextField } from "@components/form/fields/FormTextField";
import { Form } from "@components/form/Form";
import { Validator } from "@components/form/validation/Validator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  ApplicationType,
  CreateTenantDto,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useCreateTenant } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

interface CreateChildTenantDialogProps {
  tenant: Tenant;
}

interface CreateTenantDetailsFormValues
  extends Omit<CreateTenantDto, "userLimit" | "tenantType" | "isInactive"> {}

const validator = new Validator<CreateTenantDetailsFormValues>();

const schema: ValidationSchema<CreateTenantDetailsFormValues> = {
  name: validator.string().required({ message: "Name is required" })
};

const CreateChildTenantDialogBase: FunctionComponent<CreateChildTenantDialogProps & {
  onDismiss: () => void;
}> = ({ tenant, onDismiss }) => {
  const { error, mutateAsync: createTenant } = useCreateTenant();

  const defaultValues: CreateTenantDetailsFormValues = useMemo(
    () => ({
      name: tenant.name,
      country: tenant.country,
      application: "",
      customerTenantId: tenant.id
    }),
    [tenant]
  );

  const onSubmit = async (values: CreateTenantDetailsFormValues) => {
    const payload: CreateTenantDto = {
      name: values.name,
      country: values.country,
      application: values.application,
      customerTenantId: values.customerTenantId,
      userLimit: 999,
      tenantType: values.application,
      isInactive: values.application === ApplicationType.OMNI // Only set Omni Child Tenants to Inactive to start with
    };
    await createTenant(payload);
    onDismiss();
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Create Tenant",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<CreateTenantDetailsFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        showCancelButton={true}
        onCancel={onDismiss}
        error={error}
        validate={values => validator.validateWithParse(values, schema)}
      >
        <FormTextField name="name" label="Name" required />

        <FormApplicationsSelect
          name="application"
          label="Application"
          excludeCam
        />
      </Form>
    </Dialog>
  );
};

export const CreateChildTenantDialog: FunctionComponent<CreateChildTenantDialogProps> = props => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { data: hasCreatePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogTenantCreate
  );

  return (
    <>
      <DefaultButton
        onClick={() => setShowDialog(true)}
        styles={{ root: { width: "fit-content" } }}
        disabled={!hasCreatePermission}
        title={!hasCreatePermission ? NO_ACTION_PERMISSION : undefined}
      >
        Create Tenant
      </DefaultButton>

      {showDialog && (
        <CreateChildTenantDialogBase
          {...props}
          onDismiss={() => setShowDialog(false)}
        />
      )}
    </>
  );
};
