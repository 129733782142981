import { useMemo } from "react";

import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  SoftwarePackage,
  SoftwarePackageType,
  SoftwarePublisher
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useSoftwarePackages,
  useSoftwarePublishers
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { NewRolloutPackage } from "./NewRolloutPackage";

// Dto structure for all available software packages and their versions
export interface RolloutPackageSoftwarePackageSummaryDto {
  softwarePackageId: string;
  code: string;
  displayName: string;
  description?: string;
  softwarePackageType: SoftwarePackageType;
  softwarePublisher: string;
}

export interface RolloutSoftwarePackageVersionDto {
  softwarePackageVersionId: string;
  version: string;
  datePublishedUtc?: DateTime;
}

interface NewRolloutPackageDialogProps extends IDialogProps {
  existingSoftwarePackageIds: string[];
  onDismiss: () => void;
}

// Don't permit the user to add these software package types
const dependentSoftwarePackageTypes = [
  SoftwarePackageType.Bundle,
  SoftwarePackageType.Dependency
];

// Exclude the maintenance software package codes as they are set as default versions, but not the action man
const excludedMaintenanceSoftwarePackageCodes = ["ActionRunner", "Maintenance"];

export const NewRolloutPackageDialog: React.FC<NewRolloutPackageDialogProps> = ({
  existingSoftwarePackageIds,
  onDismiss,
  ...props
}) => {
  const softwarePackageQuery = useSoftwarePackages();
  const {
    data: softwarePackagesData,
    isLoading: softwarePackagesIsLoading,
    error: softwarePackagesError
  } = softwarePackageQuery;

  const softwarePublishersQuery = useSoftwarePublishers();
  const {
    data: softwarePublishersData,
    isLoading: softwarePublishersIsLoading,
    error: softwarePublishersError
  } = softwarePublishersQuery;

  const isLoading = softwarePublishersIsLoading || softwarePackagesIsLoading;

  const errorMessage =
    softwarePublishersError?.message ?? softwarePackagesError?.message;

  // Build our combined dto of all available software packages and their versions to pick from
  const softwarePackageOptions = useMemo(() => {
    if (!softwarePackagesData) return [];
    if (!softwarePublishersData) return [];

    return getRolloutPackageSoftwarePackageSummary(
      // Exclude software packages we already have in this rollout,
      // and also exclude dependent software package types.
      softwarePackagesData.filter(
        x =>
          !existingSoftwarePackageIds.find(existing => existing === x.id) &&
          !dependentSoftwarePackageTypes.includes(x.softwarePackageType) &&
          !excludedMaintenanceSoftwarePackageCodes.includes(x.code)
      ),
      softwarePublishersData
    );
  }, [
    softwarePackagesData,
    softwarePublishersData,
    existingSoftwarePackageIds
  ]);

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Add Software Package"
      }}
      minWidth="90%"
      maxWidth="90%"
      onDismiss={onDismiss}
      {...props}
    >
      <NewRolloutPackage
        items={softwarePackageOptions}
        enableShimmer={isLoading}
        errorMessage={errorMessage}
        onDismiss={onDismiss}
      />
    </Dialog>
  );
};

// Get view of software packages and their publishers
function getRolloutPackageSoftwarePackageSummary(
  softwarePackages: SoftwarePackage[],
  softwarePublishers: SoftwarePublisher[]
): RolloutPackageSoftwarePackageSummaryDto[] {
  const packages = softwarePackages.map(softwarePackage => {
    const publisher = softwarePublishers.find(
      x => x.id === softwarePackage.softwarePublisherId
    );

    return {
      softwarePackageId: softwarePackage.id,
      code: softwarePackage.code,
      displayName: softwarePackage.displayName,
      description: softwarePackage.description,
      softwarePackageType: softwarePackage.softwarePackageType,
      softwarePublisher: publisher?.code
    } as RolloutPackageSoftwarePackageSummaryDto;
  });

  return packages;
}
