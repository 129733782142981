import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { withPermissions } from "@components/withPermissions";
import { MobileAppSettings } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";
import { useGetMobileAppSettings } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";

import { Permissions } from "@libs/permissions/permissions.enum";

import { MobileAppForm } from "./mobile-app-form/MobileAppForm";

const BhMobileAppBase: React.FC = () => {
  const queryGet = useGetMobileAppSettings();

  return (
    <QueryStateIndicator<MobileAppSettings[]> {...queryGet}>
      {data => <MobileAppForm mobileAppSettings={data} />}
    </QueryStateIndicator>
  );
};

export const BhMobileApp = withPermissions(
  BhMobileAppBase,
  [Permissions.BhMobileAppRead, Permissions.BhMobileAppWrite],
  "or"
);
